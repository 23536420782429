<template>
  <q-btn
      class="q-ma-sm"
      color="primary"
      icon="qr_code"
      round
      size="sm"
      @click="dialog = true"
  >
    <q-tooltip>Автогенерация промокодов</q-tooltip>
  </q-btn>

  <q-dialog v-model="dialog">
    <q-card style="width: 500px; max-width: 80vw;">
      <q-card-section class="row items-center q-pb-none">
        <span class="text-h6">Автоматическая генерация промокодов</span>
        <q-space/>
        <q-btn v-close-popup dense flat icon="close" round/>
      </q-card-section>

      <q-card-section class="text-center">

        <q-input v-model="count"
                 class="flex-center"
                 dense
                 label="Выберите кол-во промокодов (9999 максимум)" max="9999"
                 outlined
                 style="width: 80%; display: inline-block"
                 type="number"/>

        <q-slider
            v-model="count"
            :max="9999"
            :min="1"
            :step="1"
            color="light-green"
            label
            label-always
            style="width: 80%"
            switch-label-side
        />

      </q-card-section>

      <q-card-actions align="center" class="text-grey">
        * Генерация промокодов может занять некоторое время.
      </q-card-actions>

      <q-card-actions align="right" class="text-primary">
        <q-btn v-close-popup flat label="Отмена"/>
        <q-btn color="primary" label="Сгенерировать" @click="create"/>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<script>
import { PartnerApi } from '@/api/apis/partner-api.ts';
import { Notify } from 'quasar';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PromocodeGenerator',

  props: ['modelValue'],
  emits: ['update:modelValue', 'created'],

  components: {},

  setup(props, { emit }) {
    const entity = computed(() => props.modelValue);
    const dialog = ref(false);
    const loading = ref(false);
    const count = ref(1);

    return {
      loading,
      console,
      entity,
      count,
      dialog,
      async create() {
        try {
          await new PartnerApi().partnerControllerGenerateCoupons(entity.value.offer_id, count.value);

          Notify.create('Запрос успешно создан');

          dialog.value = false;

          await sleep(2000);
          emit('created');
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>
