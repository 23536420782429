<template>
  <q-card>
    <q-card-section horizontal>
      <q-card-section class="q-pt-xs">
        <div class="text-overline">Организация</div>
        <div class="text-h5 q-mt-sm q-mb-xs">{{ entity.name }}</div>
        <div class="text-caption text-grey">{{ entity.description }}</div>
      </q-card-section>

      <q-card-section class="col-5 flex flex-center">
        <q-img
          :src="entity.logo_url"
          loading="lazy"
        >
          <template v-slot:error>
            <div
              class="absolute-full flex flex-center bg-negative text-white rounded-borders"
              style="opacity: 0.5">
              <q-icon name="warning" style="font-size: 2rem;"/>
            </div>
          </template>
        </q-img>
      </q-card-section>
    </q-card-section>

    <q-separator/>

    <q-card-actions>
      <q-btn v-for="link in links"
             :href="link.url"
             :icon="link.icon"
             flat
             round
             target="_blank"/>

      <q-space/>

      <q-dialog v-model="qrCodeDialog">
        <q-card>
          <q-card-section class="row items-center q-pb-none">
            <div class="text-h6">QR-код организации</div>
            <q-space/>
            <q-btn v-close-popup dense flat icon="close" round/>
          </q-card-section>

          <q-card-section>
            <canvas id="qr-canvas"
                    height="250"
                    style="width: 250px; height: 250px"
                    width="250"></canvas>
          </q-card-section>
        </q-card>
      </q-dialog>

      <q-btn
        flat
        icon="qr_code_2"
        round
        @click="toggleQRCodeDialog"
      >
        <q-tooltip>QR-код организации</q-tooltip>
      </q-btn>

      <q-btn
        v-if="roles.has('organization-admin')"
        :to="'/organizations/addedit/' + entity.account_no"
        flat
        icon="edit"
        round
      >
        <q-tooltip>Редактировать</q-tooltip>
      </q-btn>

    </q-card-actions>
  </q-card>

</template>

<script>
import { CONFIG } from '@/config';
import QRCode from 'qrcode';
import {
  computed, defineComponent, nextTick, ref,
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'OrganizationInfo',

  props: ['modelValue'],
  emits: ['update:modelValue'],

  components: {},

  setup(props, { emit }) {
    const qrCodeDialog = ref(false);
    const store = useStore();

    return {
      roles: computed(() => store.getters.rolesSet),
      qrCodeDialog,
      entity: computed(() => props.modelValue),
      links: computed(() => {
        const { external } = props.modelValue;
        const result = [];
        for (const {
          platform,
          url,
        } of external) {
          const settings = CONFIG.form.links.find((link) => link.platform === platform);
          if (!settings) continue;

          result.push({
            ...settings,
            url,
          });
        }

        return result;
      }),

      async toggleQRCodeDialog() {
        if (qrCodeDialog.value) {
          qrCodeDialog.value = false;
          return;
        }

        qrCodeDialog.value = true;

        await nextTick();

        QRCode.toCanvas(document.getElementById('qr-canvas'),
          JSON.stringify({ vendor_no: props.modelValue.account_no }),
          {
            width: 250,
            height: 250,
          },
          (error) => {
            if (error) console.error(error);
            console.log('success!');
          });
      },
    };
  },
});
</script>
