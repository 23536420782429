<template>
  <q-page class="q-pa-sm">
    <organization-info v-model="entity"/>
    <organization-employee v-model="entity" class="q-my-md"/>
    <organization-offers v-model="entity" class="q-my-md"/>
  </q-page>
</template>

<script>
import { OrganizationApi } from '@/api/apis/organization-api.ts';
import OrganizationEmployee from '@/components/organizations/employee';
import OrganizationInfo from '@/components/organizations/info';
import OrganizationOffers from '@/components/organizations/offers';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    OrganizationInfo,
    OrganizationEmployee,
    OrganizationOffers,
  },
  setup() {
    const route = useRoute();
    const entityId = ref(route.params.id);
    const entity = ref({
      name: '',
      description: '',
      logo_url: '',
      account_no: 0,
      external: [],
    });

    if (entityId.value) {
      new OrganizationApi().organizationControllerGetOrganization(entityId.value)
        .then(({ data }) => {
          entity.value = data;
        })
        .catch(() => entityId.value = 0);
    }

    return {
      entity,
      entityId,
    };
  },
});
</script>

<style>

</style>
