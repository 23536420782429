<template>
  <q-menu cover>
    <q-list>
      <q-item v-close-popup>
        <q-item-section>Вы уверены?</q-item-section>
      </q-item>
      <q-separator/>
      <q-item v-close-popup>
        <q-item-section>
          <q-btn color="negative" label="Нет" size="xs"/>
        </q-item-section>
      </q-item>
      <q-item v-close-popup>
        <q-item-section>
          <q-btn color="positive" label="Да" size="xs"
                 @click="ok"/>
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmMenu',
  props: ['ok'],
  setup(props) {
    return {};
  },
});
</script>

<style>

</style>
