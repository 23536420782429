<template>
  <q-card bordered class="full-width q-ma-none" flat>
    <analytics-dialog v-model="analyticsDialogShow"
                      :filename="entity.header + '_аналитика'"
                      :offer-id="entity.offer_id"
                      :organization-id="entity.organization_id"
    ></analytics-dialog>
    <q-card-section class="q-pa-xs">
      <q-item>
        <q-item-section avatar>
          <q-avatar rounded size="100px">
            <q-img :src=entity.small_image_url
                   style="width: 100px; height: 100px;">
              <template v-slot:error>
                <div
                    class="absolute-full flex flex-center bg-negative text-white rounded-borders"
                    style="opacity: 0.5; margin-top: -10px;">
                  <q-icon name="warning" style="font-size: 2rem;"/>
                </div>
              </template>
            </q-img>

            <q-tooltip>{{ entity.description }}</q-tooltip>
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <span class="text-subtitle2 text-weight-bold">

             <q-badge v-if="entity.offer_status === 'approved'" color="green" rounded/>
             <q-badge v-if="entity.offer_status === 'draft'" color="grey" rounded/>
             <q-badge v-if="entity.offer_status === 'rejected'" color="red" rounded/>
             <q-badge v-if="entity.offer_status === 'pending'" color="orange" rounded/>

            {{ entity.header }}
            <q-tooltip>Изменено {{ $filters.toHumanDateTime(entity.updated_at) }}</q-tooltip>
          </span>

          <span v-if="!entity.offer_rule.start_time" class="text-accent"
                v-text="'Срок действия акции не указан'"/>
          <span v-else>Срок действия акции:
                        {{ $filters.toHumanDate(entity.offer_rule.start_time) }}
                        -
                        {{ $filters.toHumanDate(entity.offer_rule.end_time) }}
                      </span>

          <div>
            <q-chip class="q-ml-none" color="green" icon="check_circle_outline" size="sm"
                    text-color="white">
              {{ entity.used }}
              <q-tooltip>Использовано</q-tooltip>
            </q-chip>

            <q-chip color="primary" icon="preview" size="sm" text-color="grey">
              {{ entity.in_stock }}
              <q-tooltip>В маркете</q-tooltip>
            </q-chip>

            <q-chip color="grey" icon="pending" size="sm">
              {{ entity.total }}
              <q-tooltip>Всего</q-tooltip>
            </q-chip>

          </div>
        </q-item-section>

        <q-item-section side>
          <q-item-label>
            <q-select v-if="isAdmin"
                      v-model="entity.offer_status"
                      :option-label="value => statusOptionLabels[value]"
                      :options="availableOptions"
                      dense
                      label="Статус"
                      @update:model-value="value => statusUpdated(entity.offer_id, value)"/>
          </q-item-label>
        </q-item-section>

        <q-item-section v-if="isMarketAdmin" class="text-center" side style="width: 200px">
          <q-item-label class="full-width text-right">

            <!--            <q-select v-model="entity.offer_status"-->
            <!--                      :option-label="value => statusOptionLabels[value]"-->
            <!--                      :options="Object.keys(statusOptionLabels)"-->
            <!--                      dense-->
            <!--                      label="Статус"-->
            <!--                      @update:model-value="value => statusUpdated(element.opportunity_id, value)"/>-->

            <q-btn :to="'/offers/addedit/' + entity.organization_id + '/' + entity.offer_id"
                   class="q-ma-sm" color="primary" icon="edit" round
                   size="sm">
              <q-tooltip>Изменить</q-tooltip>
            </q-btn>

            <q-btn class="q-ma-sm"
                   color="primary" icon="analytics" round size="sm"
                   @click="() => analyticsDialogShow = !analyticsDialogShow">
              <q-tooltip>Скачать аналитику</q-tooltip>
            </q-btn>

            <q-btn class="q-ma-sm" color="primary" icon="upload_file" round
                   size="sm"
                   @click="downloadSoldCodes">
              <q-tooltip>Приобретённые промокоды</q-tooltip>
            </q-btn>

            <q-btn v-if="entity.offer_status === 'draft' && !isAdmin"
                   class="q-ma-sm"
                   color="orange"
                   icon="send"
                   round
                   size="sm"
                   @click="() => statusUpdated(entity.offer_id, 'pending', true)">
              <q-tooltip>Отправить на модерацию</q-tooltip>
            </q-btn>

            <q-btn v-if="entity.offer_status === 'draft' || entity.offer_status === 'rejected'"
                   class="q-ma-sm" color="negative" icon="delete" round
                   size="sm">
              <q-tooltip>Удалить</q-tooltip>
              <confirm-menu :ok="remove"/>
            </q-btn>
          </q-item-label>

          <q-item-label class="full-width text-right">
            <q-btn v-if="entity.total"
                   class="q-ma-sm" color="primary" icon="file_download" round
                   size="sm"
                   @click="downloadCodes">
              <q-tooltip>Скачать промокоды</q-tooltip>
            </q-btn>

            <promocode-generator :model-value="entity"/>

            <q-btn
                class="q-ma-sm" color="primary" icon="file_upload" round
                size="sm">
              <q-tooltip>Загрузить файл с промокодами</q-tooltip>
              <q-popup-proxy cover transition-hide="scale" transition-show="scale">
                <q-uploader
                    :factory="uploadCodesFactory"
                    accept="txt"
                    auto-upload
                    flat
                    hide-upload-btn
                    @uploaded="codesUploadedSuccessHandler"
                    @failed="codesUploadedFailedHandler"
                    label="Файл txt, не больше 1 Мб, не больше 100к строк, не менее 5 и не более 40 символов в строке; 1 промо-код = 1 строка"
                />
              </q-popup-proxy>

            </q-btn>
          </q-item-label>

        </q-item-section>
      </q-item>
      <!--                <strong>{{ props.entity.name }}</strong>-->
    </q-card-section>
  </q-card>

</template>

<script>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { MarketApi } from '@/api/apis/market-api.ts';
import { PartnerApi } from '@/api/apis/partner-api.ts';
import ConfirmMenu from '@/components/forms/confirmMenu';
import AnalyticsDialog from '@/components/organizations/analyticsDialog';
import downloadAsFile from 'js-file-download';
import { Notify } from 'quasar';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import PromocodeGenerator from './promocodeGenerator';

const statusOptionLabels = {
  draft: 'Черновик',
  approved: 'Одобрено',
  pending: 'Модерация',
  rejected: 'Отклонено',
  archived: 'В архиве',
};

const [draft, approved, pending, rejected, archived] = Object.keys(statusOptionLabels);
const statusTransitonMatrix = {
  [draft]: [approved, pending, rejected],
  [pending]: [approved, rejected, draft],
  [rejected]: [pending, draft],
  [approved]: [archived],
  [archived]: [draft],
};

export default defineComponent({
  name: 'OfferItem',

  props: ['modelValue'],
  emits: ['update:modelValue'],

  components: {
    AnalyticsDialog,
    ConfirmMenu,
    PromocodeGenerator,
  },

  setup(props, { emit }) {
    const store = useStore();
    const entity = computed(() => props.modelValue);
    const loading = ref(false);
    const analyticsDialogShow = ref(false);

    const availableOptions = computed(() => statusTransitonMatrix[entity.value.offer_status]);

    return {
      isAdmin: computed(() => store.getters.isAdmin),
      isMarketAdmin: computed(
        () => store.getters.rolesSet.has('market-admin') || store.getters.isAdmin,
      ),
      statusOptionLabels,
      availableOptions,
      analyticsDialogShow,
      loading,
      console,
      entity,
      async remove() {
        try {
          await new MarketApi().offerControllerDeleteOffer(entity.value.offer_id);
          Notify.create('Удалено');

          emit('updated');
        } catch (e) {
          console.error(e);
        }
      },

      async downloadCodes() {
        try {
          const { data } = await new AdminApi().marketControllerExportToCSV(
            entity.value.offer_id,
          );

          downloadAsFile(data, `${entity.value.header}_промокоды.csv`);
        } catch (e) {
          console.error(e);
        }
      },

      async downloadSoldCodes() {
        try {
          const { data } = await new AdminApi().getSoldCoupons(
            entity.value.offer_id,
          );

          downloadAsFile(data, `${entity.value.header}_проданные_промокоды.csv`);
        } catch (e) {
          console.error(e);
        }
      },

      uploadCodesFactory: async (files) => new PartnerApi().partnerControllerCodeUploadXhrData(entity.value.offer_id),

      codesUploadedSuccessHandler: () => {
        Notify.create('Загружено');
        // await sleep(1000);
        emit('updated');
      },

      codesUploadedFailedHandler: (err) => {
        Notify.create({
          type: 'negative',
          caption: 'Ошибка API',
          message: JSON.parse(err.xhr.response).message,
          timeout: 10000,
          closeBtn: true,
        });
      },

      async statusUpdated(id, status, updateLocalState) {
        const request = { offer_status: status };

        try {
          await new MarketApi().offerControllerUpdateOfferStatus(request, id);

          Notify.create('Статус сохранен');

          if (updateLocalState) entity.value.offer_status = status;
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>
