<template>
  <q-dialog v-model="dialog">
    <q-card style="width: 500px; max-width: 80vw;">
      <q-card-section class="row items-center q-pb-none">
        <span class="text-h6">Скачать аналитику</span>
        <q-space/>
        <q-btn v-close-popup dense flat icon="close" round/>
      </q-card-section>

      <q-card-section class="text-center">
        <div class="q-mx-xs" style="max-width: 180px; display: inline-block">
          <q-input v-model="period.from" dense label="Период (начало)" outlined>
            <template v-slot:prepend>
              <q-icon class="cursor-pointer" name="event">
                <q-popup-proxy cover transition-hide="scale" transition-show="scale">
                  <q-date v-model="period.from" mask="YYYY-MM-DD">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup color="primary" flat label="Close"/>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>

        <div class="q-mx-xs" style="max-width: 180px; display: inline-block">
          <q-input v-model="period.to" dense label="Период (конец)" outlined>
            <template v-slot:prepend>
              <q-icon class="cursor-pointer" name="event">
                <q-popup-proxy cover transition-hide="scale" transition-show="scale">
                  <q-date v-model="period.to" mask="YYYY-MM-DD">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup color="primary" flat label="Close"/>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>

        <div class="q-mx-xs" style="max-width: 150px; display: inline-block; padding-top: -8px">
          <q-btn class="cursor-pointer" color="secondary" icon="event" round
                 style="margin-top: -14px;">
            <q-popup-proxy cover transition-hide="scale" transition-show="scale">
              <q-date v-model="period" mask="YYYY-MM-DD" range>
                <div class="row items-center justify-end">
                  <q-btn v-close-popup color="primary" flat label="Close"/>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
        </div>
      </q-card-section>

      <q-card-section class="text-center">
        <q-select v-model="sort"
                  :options="sortOptions"
                  dense
                  label="Сортировать по:"
                  outlined style="width: 90%; display: inline-block;"
        ></q-select>
      </q-card-section>

      <q-card-section class="text-center">
        <q-select v-model="sortOrder"
                  :options="sortOrderOptions"
                  dense
                  label="Порядок сортировки"
                  outlined style="width: 90%; display: inline-block;"
        ></q-select>
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn v-close-popup flat label="Отмена"/>
        <q-btn color="primary" label="Скачать" @click="download"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { StatisticsApi } from '@/api/apis/statistics-api.ts';
import downloadAsFile from 'js-file-download';

const sortOptions = [
  {
    label: 'Купону',
    value: 'code',
  },

  {
    label: 'Времени покупки',
    value: 'purchased_at',
  },

  {
    label: 'Времени активации',
    value: 'activated_at',
  },
];

const sortOrderOptions = [
  {
    label: 'По возрастанию',
    value: 'ASC',
  },

  {
    label: 'По убыванию',
    value: 'DESC',
  },
];

export default defineComponent({
  name: 'AnalyticsDialog',

  emits: ['update:modelValue'],
  props: ['organizationId', 'offerId', 'accountId', 'filename', 'modelValue'],

  setup(props, { emit }) {
    const dialog = computed({
      get: () => props.modelValue,
      set: (v) => emit('update:modelValue', v),
    });

    const loading = ref(false);
    const sort = ref(sortOptions[0]);
    const sortOrder = ref(sortOrderOptions[0]);
    const period = ref({
      from: new Date(Date.now() - 86400 * 30 * 1000).toISOString().split('T')[0],
      to: new Date().toISOString().split('T')[0],
    });

    return {
      sortOrderOptions,
      sortOrder,
      sortOptions,
      sort,
      loading,
      console,
      period,
      dialog,
      async download() {
        try {
          const { data } = await new StatisticsApi().organizationControllerGetCouponActivations(
            props.organizationId,
            props.accountId || undefined,
            period.value.from,
            period.value.to,
            sort.value.value,
            sortOrder.value.value,
            props.offerId || undefined,
          );

          downloadAsFile(data, `${props.filename}.csv`);

          dialog.value = false;
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>
